body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html {
  font-size: 12px;
  font-weight: 500;
  font-family: "Inter", sans-serif;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  display: none;
}

/* mui table style */
.Mui-TableHeadCell-Content-Wrapper {
  white-space: pre-wrap !important;
}

.mui_table__container {
  max-height: 70vh !important;
  overflow-y: auto;
  border-radius: 0 0 10px 10px;
}
.mui_table__container::-webkit-scrollbar {
  width: 8 !important;
  border-radius: 10px;
}
.mui_table__container::-webkit-scrollbar-track {
  border: 1px solid #48657c;
  border-radius: 10px;
}
.mui_table__container::-webkit-scrollbar-thumb {
  background-color: #465f72;
  border-radius: 10px;
}
.mui_table__container::-webkit-scrollbar:vertical:decrement {
  margin-top: 40px !important;
}
@media only screen and (max-width: 768px) {
  html {
    font-size: 8px;
  }
}

@media only screen and (max-width: 992px) {
  html {
    font-size: 10px;
  }
}

/* style for material form input  */
.MuiFormControl-root label {
  padding-top: 3px;
}

/* body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #0c2f49 !important;
}

code {
  font-family: "Inter" source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* .bg-login {
  background-image: url("assets/media/images/login-bg.png");
  background-color: linear-gradient(180deg, #058f38 0%, #b7f92b 100%);
  background-size: cover;
  background-repeat: no-repeat;
} */

.min-70vh {
  min-height: 70vh;
}

.min-100vh {
  min-height: 100vh;
}

/* for Accordian style start */
.customaccordian_style {
  box-shadow: none !important;
  border-radius: 15px !important;
  border: 1.5px solid #b6c8d6 !important;
  margin-top: 10px !important;
}

.accordiantDetail_border {
  border-top: 1.5px solid #b6c8d6 !important;
}

.accordian_title {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.MuiAccordion-root::before {
  opacity: 0 !important;
}

.slick-track {
  width: fit-content;
}

/* for success or reject button */
.dialog_successButton {
  border-radius: 8px !important;
  border: 1px solid rgba(7, 177, 3, 0.25) !important;
  background: rgba(20, 241, 15, 0.03) !important;
  text-transform: capitalize !important;
  color: #07b103 !important;
  box-shadow: none !important;
}
.dialog_successButton:hover {
  border: 1px solid rgba(7, 177, 3, 0.25);
  background: rgba(20, 241, 15, 0.03);
  color: #07b103;
}
.dialog_errorButton {
  border-radius: 8px !important;
  border: 1px solid rgba(241, 15, 15, 0.25) !important;
  background: rgba(241, 15, 15, 0.03) !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  color: #ea1c1c !important;
}
.dialog_errorButton:hover {
  border: 1px solid rgba(241, 15, 15, 0.25) !important;
  background: rgba(241, 15, 15, 0.03) !important;
  color: #ea1c1c !important;
}

